import NProgress from 'nprogress';

NProgress.configure({
  showSpinner: false,
  easing: '',
  speed: 100,
  trickleSpeed: 50,
  minimum: 0.3,
});

// Show progress bar when loading begins
NProgress.start();

window.addEventListener('load', () => {
  NProgress.done();

  document.querySelectorAll('a').forEach((link) => {
    if (link.getAttribute('href')?.startsWith('#')) {
      return;
    }
    link.addEventListener('click', () => {
      console.log(document.querySelectorAll('a'));
      setTimeout(() => {
        NProgress.start();
      }, 50);
    });
  });
});
